import Cookie from 'js-cookie';

var tablesort = require('tablesort');

(function(){
  var cleanNumber = function(i) {
    return i.replace(/[^\-?0-9.]/g, '');
  },

  compareNumber = function(a, b) {
    a = parseFloat(a);
    b = parseFloat(b);

    a = isNaN(a) ? 0 : a;
    b = isNaN(b) ? 0 : b;

    return a - b;
  };

  tablesort.extend('number', function(item) {
    return item.match(/^[-+]?[£\x24Û¢´€]?\d+\s*([,\.]\d{0,2})/) || // Prefixed currency
      item.match(/^[-+]?\d+\s*([,\.]\d{0,2})?[£\x24Û¢´€]/) || // Suffixed currency
      item.match(/^[-+]?(\d)*-?([,\.]){0,1}-?(\d)+([E,e][\-+][\d]+)?%?$/); // Number
  }, function(a, b) {
    a = cleanNumber(a);
    b = cleanNumber(b);

    return compareNumber(b, a);
  });
}());

function saveSort(evt) {
    const target = evt.currentTarget;

    var direction = "descending";
    if (target.attributes["aria-sort"] &&
        target.attributes["aria-sort"].value == "descending") {
        direction = "ascending";
    }

    const column = target.attributes["data-column"].value;
    Cookie.set("song-sort-column", column);
    Cookie.set("song-sort-direction", direction);
}

function applySavedSort() {
    const column = Cookie.get("song-sort-column");
    const direction = Cookie.get("song-sort-direction");

    if (column === undefined || direction === undefined) {
        return;
    }

    const actual_direction = direction == "descending" ? "ascending" : "descending";

    var current_default = $("th[data-sort-default]");
    current_default.removeAttr("data-sort-default");
    var target_column = $("th[data-column='" + column + "']");
    target_column.attr("data-sort-default", true);
    target_column.attr("aria-sort", actual_direction);
}

function onSongRefresh(response) {
    $("#song-filter-toggle").show();
    $("#songs-loading").hide();
    $("#song-data").html(response);
    $("#song-table th").click(saveSort);
    applySavedSort();
    var sorter = tablesort(document.getElementById("song-table"), {
        descending: true
    });
}

function onSongError(err) {
    $("#songs-loading").hide();
    $("#songs-error").show();
}

function loadSongs() {
    $("#song-data").html("");
    $("#songs-loading").show();

    const theform = $("#song-filter-form");
    theform.find("button").blur();

    const serviceFilter = theform.find("#id_service_type")[0].value;

    var queryString = [];
    if (serviceFilter !== "") {
        queryString.push("service=" + serviceFilter);
    }

    const start_date = $("#id_start_date").val();
    if (start_date !== "") {
        queryString.push("start_date=" + start_date);
    }
    const end_date = $("#id_end_date").val();
    if (end_date !== "") {
        queryString.push("end_date=" + end_date);
    }

    const url = window.location.pathname + "data/?" + queryString.join("&");
    $.ajax(url).done(onSongRefresh).error(onSongError);
}

function applyFilters(evt) {
    evt.preventDefault();
    loadSongs();
}

function clearFilters(evt) {
    evt.preventDefault();

    $("#id_service_type").val("").trigger("change");

    var fp = $("#id_start_date")[0]._flatpickr;
    fp.clear();
    fp = $("#id_end_date")[0]._flatpickr;
    fp.clear();

    loadSongs();
}

function toggleFilters(evt) {
    evt.preventDefault();
    evt.currentTarget.blur();
    $("#song-filter-form").toggle();
}

$(document).on('ready', function () {
    const elements = $("#song-data");

    if (elements.length === 0) {
        return;
    }

    var pickers = $(".input-frontdatewidget input");
    pickers.flatpickr({
        altInput: true,
        altFormat: "l J F, Y",
        dateFormat: "Y-m-d",
    });

    $("#song-filter-form").submit(applyFilters);
    $("#clear-filters").click(clearFilters);
    $("#song-filter-toggle").click(toggleFilters);

    loadSongs();
});
