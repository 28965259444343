function getVisibleDate(week_offset) {
    const milli_offset = week_offset * 7 * 24 * 60 * 60 * 1000;

    const firstRow = document.querySelector("#diary-event-table table tr");
    const rawDate = firstRow.attributes["data-date"].value;

    return new Date(Date.parse(rawDate) + milli_offset);
}

function enableDisableLinks() {
    const date = getVisibleDate(0);
    const now = new Date();
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    const diffDays = (date.getTime() - now.getTime()) / oneDayInMillis;

    const previousLink = document.querySelector(".page-item.previous");
    const nextLink = document.querySelector(".page-item.next");

    if (diffDays < -7) {
        previousLink.classList.add("disabled");
    }
    else {
        previousLink.classList.remove("disabled");
    }

    if (diffDays > 31) {
        nextLink.classList.add("disabled");
    }
    else {
        nextLink.classList.remove("disabled");
    }
}

function onDiaryRefresh(body) {
    $("#diary-event-table").html(body);
    enableDisableLinks();
}

function refreshDiary(yyyymmdd) {
    const base_url = document.querySelector("#diary-event-table").attributes["data-refresh"].value;
    const url = base_url + "?date=" + yyyymmdd;
    $.ajax(url).done(onDiaryRefresh);
}

function adjustWeek(week_offset) {
    const date = getVisibleDate(week_offset);
    const yyyymmdd = date.toISOString().split("T")[0].replace("-", "").replace("-", "");

    refreshDiary(yyyymmdd);
}

function onEventPaginationClick(evt) {
    evt.preventDefault();
    const el = $(this);

    if (el.attr("href") == "#previous") {
        adjustWeek(-1);
    }
    else {
        adjustWeek(1);
    }
}

$(document).ready(function() {
    const links = $(".diary-event-link");

    if (links.length === 0) {
        return;
    }

    links.click(onEventPaginationClick);

    enableDisableLinks();
});
