function onShowMoreClick(event) {
    event.preventDefault();
    const link = $(event.target);
    const table = link.parentsUntil("table");
    table.find("tr").show();
    table.find("tr.showmore").hide();
}

function shrinkTable(table) {
    const defaultNumberOfRowsToDisplay = 5;

    const rows = $(table).find("tbody tr");

    if (rows.length <= defaultNumberOfRowsToDisplay + 1) {
        return;
    }

    var showmore = $(table).find("tbody tr.showmore");
    showmore[0].style.display = "table-row";

    for (var i = 0; i < rows.length - 1; ++i) {
        if (i < defaultNumberOfRowsToDisplay) continue;
        rows[i].style.display = "none";
    }
}

$(document).ready(function() {
    const tables = $("table.upcoming-events");

    if (tables.length === 0) {
        return;
    }

    tables.each(item => shrinkTable(tables[item]));

    const buttons = $("table.upcoming-events tr.showmore a").click(onShowMoreClick);
});
