function onHamburgerClick() {
    const link = $(this);
    const expanded = link.attr("aria-expanded");

    if (expanded == "false") {
        link.attr("aria-expanded", "true");
        $(".u-header__sub-menu").slideDown();
    }
    else {
        link.attr("aria-expanded", "false");
        $(".u-header__sub-menu").slideUp();
    }
}

function onMegaMenuClick() {
    const link = $(this);
    const menuKey = link.attr("data-target");
    var megaMenu = $(menuKey);

    if (megaMenu.is(":visible")) {
        megaMenu.slideUp();
        return;
    }

    var visibleMenu = $(".u-header__sub-menu:visible");

    if (visibleMenu.length !== 0) {
        visibleMenu.slideUp("fast", function() {
            megaMenu.slideDown();
        });
    }
    else {
        megaMenu.slideDown();
    }
}

$(document).mouseup(function(e) {
    if ($(".u-hamburger").is(":visible")) {
        return;
    }

    var container = $(".u-header__sub-menu:visible");

    // if the target of the click isn't the container nor a descendant
    // of the container.
    if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.slideUp();
    }
});

$(document).keyup(function(e) {
    if (e.keyCode === 27) {
        var container = $(".u-header__sub-menu:visible");
        $(".u-hamburger").attr("aria-expanded", "false");
        container.slideUp();
    }
});

$(document).ready(function() {
    const hamburger = $(".u-hamburger").click(onHamburgerClick);

    const links = $(".u-header__nav-link");

    if (links.length === 0) {
        return;
    }

    links.click(onMegaMenuClick);
});
